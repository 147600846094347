import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"NotFound"},[_c(VContainer,[_c(VLayout,{attrs:{"row":"","wrap":""}},[_c(VFlex,{staticClass:"text-center",attrs:{"xs12":""}},[_c(VImg,{staticStyle:{"margin":"0 auto"},attrs:{"width":"500px","src":"https://onlinezebra.com/wp-content/uploads/2019/01/error-404-not-found.jpg"}}),_c(VCard,{attrs:{"height":"400","color":"transparent","flat":""}},[_c('div',{staticClass:"display-3 mt-5"},[_vm._v(_vm._s(_vm._f("trans")(_vm.resource))+" not found.")]),_c('div',{staticClass:"grey--text lighten-5"},[_vm._v(" The "+_vm._s(_vm._f("trans")(_vm.resource))+" you are trying to get to never existed in this reality, or has migrated to a parallel universe. ")]),_c('div',{staticClass:"paragraph-text mt-2"},[_c('br'),_c('br'),_vm._v("Try going back to home page and repeating your action. Or, contact helpdesk for support. ")])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
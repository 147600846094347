<!-- views/NotFound.vue -->

<template>
	<div class="NotFound">
		<v-container>
			<v-layout row wrap>
				<v-flex xs12 class="text-center">
					<v-img
						width="500px"
						style="margin: 0 auto"
						src="https://onlinezebra.com/wp-content/uploads/2019/01/error-404-not-found.jpg"
					></v-img>
					<v-card height="400" color="transparent" flat>
						<div class="display-3 mt-5">{{ resource | trans }} not found.</div>
						<div class="grey--text lighten-5">
							The {{ resource | trans }} you are trying to get to never existed in this reality, or has migrated to a
							parallel universe.
						</div>
						<div class="paragraph-text mt-2">
							<br />
							<br />Try going back to home page and repeating your action. Or, contact helpdesk for support.
						</div>
					</v-card>
				</v-flex>
			</v-layout>
		</v-container>
	</div>
</template>
<script>
export default {
	props: {
		resource: {
			type: String,
			default: "Page",
		},
	},
};
</script>

<style>
.paragraph-text {
	font-size: 18px;
}
</style>

<!-- views/NotFound.vue -->

<template>
	<div class="NotFound">
		<v-container>
			<v-layout row wrap>
				<v-flex xs12 class="text-center">
					<v-img
						width="500px"
						style="margin: 0 auto"
						src="https://assets.materialup.com/uploads/28eab5e0-b6ca-42c7-bb39-b6155fa2443a/preview.png"
					></v-img>
					<v-card height="400" color="transparent" flat>
						<div class="display-2 mt-5">Internal Server Error</div>
						<div class="grey--text display-1 lighten-5">
							Nuestra culpa no la tuya
							<br />:(
						</div>
						<div class="paragraph-text mt-2">
							<br />
							<br />
							{{ paragraph }}
						</div>
					</v-card>
				</v-flex>
			</v-layout>
		</v-container>
	</div>
</template>
<script>
export default {
	props: {
		resource: {
			type: String,
			default: "Page",
		},
		paragraph: {
			type: String,
			default: "Try going back to home page and repeating your action. Or, contact helpdesk for support.",
		},
	},
};
</script>

<style>
.paragraph-text {
	font-size: 18px;
}
</style>

import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"NotFound"},[_c(VContainer,[_c(VLayout,{attrs:{"row":"","wrap":""}},[_c(VFlex,{staticClass:"text-center",attrs:{"xs12":""}},[_c(VImg,{staticStyle:{"margin":"0 auto"},attrs:{"width":"500px","src":"https://assets.materialup.com/uploads/28eab5e0-b6ca-42c7-bb39-b6155fa2443a/preview.png"}}),_c(VCard,{attrs:{"height":"400","color":"transparent","flat":""}},[_c('div',{staticClass:"display-2 mt-5"},[_vm._v("Internal Server Error")]),_c('div',{staticClass:"grey--text display-1 lighten-5"},[_vm._v(" Nuestra culpa no la tuya "),_c('br'),_vm._v(":( ")]),_c('div',{staticClass:"paragraph-text mt-2"},[_c('br'),_c('br'),_vm._v(" "+_vm._s(_vm.paragraph)+" ")])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }